import {Routes} from '@angular/router';
import {LoginComponent} from '../view/pages/login/login.component';
import {AuthGuard} from './guards/auth.guard';
import {UserPageComponent} from '../view/pages/user-page/user-page.component';
import {SystemRegistryComponent} from '../view/pages/settings/system-registry/system-registry.component';
import {SettingsComponent} from '../view/pages/settings/settings.component';
import {DashboardComponent} from '../view/pages/dashboard/dashboard.component';
import {ModuleInfoComponent} from '../view/pages/settings/system-registry/module-info/module-info.component';
import {RackInfoComponent} from '../view/pages/settings/system-registry/rack-info/rack-info.component';
import {UsersComponent} from '../view/pages/settings/users/users.component';
import {SlotInfoComponent} from '../view/pages/settings/system-registry/slot-info/slot-info.component';
import {ProfileCreateComponent} from '../view/pages/settings/profiles/profile-create/profile-create.component';
import {ProfileEditComponent} from '../view/pages/settings/profiles/profile-edit/profile-edit.component';
import {RegistriesComponent} from '../view/pages/settings/registries/registries.component';
import {FarmingsInfoComponent} from '../view/pages/settings/registries/farmings-info/farmings-info.component';
import {PlantsInfoComponent} from '../view/pages/settings/registries/plants-info/plants-info.component';
import {SpeciesInfoComponent} from '../view/pages/settings/registries/species-info/species-info.component';
import {PrivacyGuard} from './guards/privacy.guard';
import {SolutionsInfoComponent} from '../view/pages/settings/registries/solutions-info/solutions-info.component';
import {SubstratesComponent} from '../view/pages/settings/substrates/substrates.component';

export const ROUTES: Routes = [
    /* LOGIN PAGE */
    {path: 'login', component: LoginComponent},

    /* DASHBOARD */
    {path: '', component: DashboardComponent, canActivate: [AuthGuard]},

    /* CURRENT USER PROFILE PAGE */
    {path: 'user/edit', component: UserPageComponent, canActivate: [AuthGuard]},

    /* SYSTEM SETTINGS */
    {path: 'settings', component: SettingsComponent, canActivate: [AuthGuard]},

    /* PROFILES AND USERS */
    {path: 'settings/users', component: UsersComponent, canActivate: [AuthGuard, PrivacyGuard]},
    {path: 'settings/users/edit', component: UserPageComponent, canActivate: [AuthGuard, PrivacyGuard]},
    {path: 'settings/users/create', component: UserPageComponent, canActivate: [AuthGuard, PrivacyGuard]},

    {path: 'settings/profiles/create', component: ProfileCreateComponent, canActivate: [AuthGuard, PrivacyGuard]},
    {path: 'settings/profiles/edit', component: ProfileEditComponent, canActivate: [AuthGuard, PrivacyGuard]},

    /* SYSTEM CONFIGURATION */
    {path: 'settings/system', component: SystemRegistryComponent, canActivate: [AuthGuard, PrivacyGuard]},

    {path: 'settings/system/module', component: ModuleInfoComponent, canActivate: [AuthGuard, PrivacyGuard]},
    {path: 'settings/system/rack', component: RackInfoComponent, canActivate: [AuthGuard, PrivacyGuard]},
    {path: 'settings/system/slot', component: SlotInfoComponent, canActivate: [AuthGuard, PrivacyGuard]},

    /* REGISTRIES */
    {path: 'settings/registries', component: RegistriesComponent, canActivate: [AuthGuard, PrivacyGuard]},
    {path: 'settings/registries/farmings', component: FarmingsInfoComponent, canActivate: [AuthGuard, PrivacyGuard]},
    {path: 'settings/registries/plants', component: PlantsInfoComponent, canActivate: [AuthGuard, PrivacyGuard]},
    {path: 'settings/registries/species', component: SpeciesInfoComponent, canActivate: [AuthGuard, PrivacyGuard]},
    {path: 'settings/registries/solutions', component: SolutionsInfoComponent, canActivate: [AuthGuard, PrivacyGuard]},
    {path: 'settings/registries/substrates', component: SubstratesComponent, canActivate: [AuthGuard, PrivacyGuard]},

    /* OTHERWISE REDIRECT TO HOME */
    {path: '**', redirectTo: ''},
];
