const API_HOST_URL = 'https://165.22.125.131:8000';

export const environment = {
    production: false,
    apiHost: `${API_HOST_URL}/`,
    server: {
        login: `${API_HOST_URL}/login`,
        whoami: `${API_HOST_URL}/whoami`,
        refresh: `${API_HOST_URL}/refresh`,
        users: `${API_HOST_URL}/users`,
        profiles: `${API_HOST_URL}/profiles`,
        systemregistry: `${API_HOST_URL}/systemregistry`,
        modules: `${API_HOST_URL}/modules`,
        racks: `${API_HOST_URL}/racks`,
        slots: `${API_HOST_URL}/slots`,

        seeds: `${API_HOST_URL}/seeds`,
        fullseeds: `${API_HOST_URL}/fullseeds`,

        lamps: `${API_HOST_URL}/lamps`,

        farmings: `${API_HOST_URL}/farmings`,
        plants: `${API_HOST_URL}/plants`,
        species: `${API_HOST_URL}/species`,
        solutions: `${API_HOST_URL}/solutions`,
        substrates: `${API_HOST_URL}/substrates`,

    }
};
