import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {User} from '../model/user';
import {ModuleInfo} from '../model/system/ModuleInfo';
import {RackInfo} from '../model/system/RackInfo';
import {SlotInfo} from '../model/system/SlotInfo';
import {LampInfo} from '../model/system/LampInfo';
import {IResponse} from '../model/interface/IResponse';
import {SeedsInfo} from '../model/product/SeedsInfo';
import {ISystemRegistry} from '../model/interface/ISystemRegistry';
import {ProfilesInfo} from '../model/system/ProfilesInfo';
import {FarmingsInfo} from '../model/registries/farmings-info';
import {PlantsInfo} from '../model/registries/plants-info';
import {SpeciesInfo} from '../model/registries/species-info';
import {SolutionsInfo} from '../model/registries/solutions-info';
import {SubstrateInfo} from '../model/substrate-info';

@Injectable({
    providedIn: 'root'
})
export class OnoApiService {

    constructor(
        private http: HttpClient
    ) {
    }

    /*
    * Login request
    * */
    login({username, password}) {
        return this.http.post<{ Code: number, Token: string }>(
            `${environment.server.login}`,
            {username, password},
            {
                observe: 'response',
                withCredentials: true
            }
        );
    }

    /*
    * Return current user information
    * */
    whoami(): Observable<User> {
        return this.http.get<User>(`${environment.server.whoami}`, {
            withCredentials: true
        });
    }

    /*
    * Refresh request
    * */
    refresh() {
        return this.http.get(`${environment.server.refresh}`, {
            withCredentials: true
        });
    }

    /*
    * Edits current user information (username is read from the token; the body contains only fields to change)
    * */
    userPut(userID, values) {
        return this.http.put(
            `${environment.server.users}/${userID}`,
            {...values},
            {
                responseType: 'text',
                withCredentials: true
            }
        );
    }

    /*
    * Returns all users
    * */
    usersGet(): Observable<User[]> {
        return this.http.get<User[]>(
            `${environment.server.users}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Returns information for the user passed into URL parameters
    * */
    userGet(userName): Observable<User> {
        return this.http.get<User>(
            `${environment.server.users}/${userName}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Adds a new user
    * */
    userPost(values: User): Observable<User> {
        return this.http.post<User>(
            `${environment.server.users}`,
            {...values},
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Deletes the user passed into URL parameters
    * */
    userDelete(userName): Observable<User> {
        return this.http.delete<User>(
            `${environment.server.users}/${userName}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Returns all users
    * */
    profilesGet(): Observable<ProfilesInfo[]> {
        return this.http.get<ProfilesInfo[]>(
            `${environment.server.profiles}`,
            {
                responseType: 'json'
            }
        );
    }

    profilesPost(values: ProfilesInfo): Observable<IResponse> {
        return this.http.post<IResponse>(
            `${environment.server.profiles}`,
            {...values},
            {
                responseType: 'json'
            }
        );
    }

    profilesPut(profileName: string, values: any): Observable<IResponse> {
        return this.http.put<IResponse>(
            `${environment.server.profiles}/${profileName}`,
            {...values},
            {
                responseType: 'json'
            }
        );
    }

    profilesDelete(profileName: string): Observable<IResponse> {
        return this.http.delete<IResponse>(
            `${environment.server.profiles}/${profileName}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Returns all systems registries
    * */
    systemRegistryListGet(systemName?: string): Observable<ISystemRegistry[]> {
        return this.http.get<ISystemRegistry[]>(
            `${environment.server.systemregistry}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Returns systems registry entity
    * */
    systemRegistryGet(systemName: string): Observable<ISystemRegistry> {
        return this.http.get<ISystemRegistry>(
            `${environment.server.systemregistry}/${systemName}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Returns all systems registries
    * */
    systemRegistryPost(values: ISystemRegistry): Observable<any> {
        return this.http.post<any>(
            `${environment.server.systemregistry}`,
            {...values},
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Edits system registries (the body always contains the system name and only the fields to change)
    * */
    systemRegistryPut(systemID: string, values: any): Observable<any> {
        return this.http.put<any>(
            `${environment.server.systemregistry}/${systemID}`,
            {...values},
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Deletes the system registry passed into URL parameters
    * */
    systemRegistryDelete(registryName: string): Observable<any> {
        return this.http.delete<any>(
            `${environment.server.systemregistry}/${registryName}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Returns all modules
    * */
    modulesGet(): Observable<ModuleInfo[]> {
        return this.http.get<ModuleInfo[]>(
            `${environment.server.modules}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Returns the information of the module
    * */
    moduleGet(moduleID: number): Observable<ModuleInfo> {
        return this.http.get<ModuleInfo>(
            `${environment.server.modules}/${moduleID}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Edits a module (the element name to edit is specified in the URL parameters; the body contains only the fields to edit)
    * */
    modulePut(moduleID: number, values: any): Observable<IResponse> {
        return this.http.put<IResponse>(
            `${environment.server.modules}/${moduleID}`,
            {...values},
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Create new module
    * */
    modulePost(values: any): Observable<IResponse> {
        return this.http.post<IResponse>(
            `${environment.server.modules}`,
            {...values},
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Delete module
    * */
    moduleDelete(moduleID: number): Observable<IResponse> {
        return this.http.delete<IResponse>(
            `${environment.server.modules}/${moduleID}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Returns all racks
    * */
    racksGet(): Observable<RackInfo[]> {
        return this.http.get<RackInfo[]>(
            `${environment.server.racks}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Returns the information of the rack
    * */
    rackGet(rackID: number): Observable<RackInfo> {
        return this.http.get<RackInfo>(
            `${environment.server.racks}/${rackID}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Modifies the rack
    * */
    rackPut(rackID: number, values: any): Observable<IResponse> {
        return this.http.put<IResponse>(
            `${environment.server.racks}/${rackID}`,
            {...values},
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Create new rack
    * */
    rackPost(values: any): Observable<IResponse> {
        return this.http.post<IResponse>(
            `${environment.server.racks}`,
            {...values},
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Delete rack
    * */
    rackDelete(rackId: number): Observable<IResponse> {
        return this.http.delete<IResponse>(
            `${environment.server.racks}/${rackId}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Returns all slots
    * */
    slotsGet(): Observable<SlotInfo[]> {
        return this.http.get<SlotInfo[]>(
            `${environment.server.slots}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Returns slot info
    * */
    slotGet(slotID): Observable<SlotInfo> {
        return this.http.get<SlotInfo>(
            `${environment.server.slots}/${slotID}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Modifies the slot
    * */
    slotPut(slotID: number, values: any): Observable<IResponse> {
        return this.http.put<IResponse>(
            `${environment.server.slots}/${slotID}`,
            {...values},
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Create new slot
    * */
    slotPost(values: any): Observable<IResponse> {
        return this.http.post<IResponse>(
            `${environment.server.slots}`,
            {...values},
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Delete slot
    * */
    slotDelete(slotId: number): Observable<IResponse> {
        return this.http.delete<IResponse>(
            `${environment.server.slots}/${slotId}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Returns all lamps
    * */
    lampsGet(): Observable<LampInfo[]> {
        return this.http.get<LampInfo[]>(
            `${environment.server.lamps}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Returns all seeds
    * */
    seedsGet(): Observable<SeedsInfo[]> {
        return this.http.get<SeedsInfo[]>(
            `${environment.server.fullseeds}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * Modifies the seed
    * */
    seedPut(SeedType: string, values: any): Observable<IResponse> {
        return this.http.put<IResponse>(
            `${environment.server.seeds}/${SeedType}`,
            {...values},
            {
                responseType: 'json'
            }
        );
    }

    /*
    *  GET system component info
    * */
    getSystemComponentInfo<T>(params: string[]): Observable<T> {
        return this.http.get<T>(
            `${environment.apiHost}${params.join('/')}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    *  POST system component info
    * */
    postSystemComponentInfo<T>(params: any[], data: object): Observable<IResponse> {
        return this.http.post<IResponse>(
            `${environment.apiHost}${params.join('/')}`,
            {...data},
            {
                responseType: 'json'
            }
        );
    }

    /*
    *  PUT system component info
    * */
    putSystemComponentInfo<T>(params: any[], data: object): Observable<IResponse> {
        return this.http.put<IResponse>(
            `${environment.apiHost}${params.join('/')}`,
            {...data},
            {
                responseType: 'json'
            }
        );
    }

    /*
    *  DELETE system component info
    * */
    deleteSystemComponentInfo<T>(params: any[]): Observable<IResponse> {
        return this.http.delete<IResponse>(
            `${environment.apiHost}${params.join('/')}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    *  GET system components list
    * */
    getSystemComponentList<T>(params: string[]): Observable<T[]> {
        return this.http.get<T[]>(
            `${environment.apiHost}${params.join('/')}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * GET farmings
    * */
    getFarmings(): Observable<FarmingsInfo[]> {
        return this.http.get<FarmingsInfo[]>(
            `${environment.server.farmings}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * POST farmings
    * */
    postFarmings(value: FarmingsInfo): Observable<IResponse> {
        return this.http.post<IResponse>(
            `${environment.server.farmings}`,
            {...value},
            {
                responseType: 'json'
            }
        );
    }

    /*
    * PUT farmings
    * */
    putFarmings(id: string, value: any): Observable<IResponse> {
        return this.http.put<IResponse>(
            `${environment.server.farmings}/${id}`,
            {...value},
            {
                responseType: 'json'
            }
        );
    }

    /*
    * DELETE farmings
    * */
    deleteFarmings(id: string): Observable<IResponse> {
        return this.http.delete<IResponse>(
            `${environment.server.farmings}/${id}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * GET farmings
    * */
    getPlants(): Observable<PlantsInfo[]> {
        return this.http.get<PlantsInfo[]>(
            `${environment.server.plants}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * POST farmings
    * */
    postPlants(value: PlantsInfo): Observable<IResponse> {
        return this.http.post<IResponse>(
            `${environment.server.plants}`,
            {...value},
            {
                responseType: 'json'
            }
        );
    }

    /*
    * PUT farmings
    * */
    putPlants(id: string, value: any): Observable<IResponse> {
        return this.http.put<IResponse>(
            `${environment.server.plants}/${id}`,
            {...value},
            {
                responseType: 'json'
            }
        );
    }

    /*
    * DELETE farmings
    * */
    deletePlants(id: string): Observable<IResponse> {
        return this.http.delete<IResponse>(
            `${environment.server.plants}/${id}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * GET species
    * */
    getSpecies(): Observable<SpeciesInfo[]> {
        return this.http.get<SpeciesInfo[]>(
            `${environment.server.species}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * POST species
    * */
    postSpecies(value: SpeciesInfo): Observable<IResponse> {
        return this.http.post<IResponse>(
            `${environment.server.species}`,
            {...value},
            {
                responseType: 'json'
            }
        );
    }

    /*
    * PUT species
    * */
    putSpecies(id: string, value: any): Observable<IResponse> {
        return this.http.put<IResponse>(
            `${environment.server.species}/${id}`,
            {...value},
            {
                responseType: 'json'
            }
        );
    }

    /*
    * DELETE species
    * */
    deleteSpecies(id: string): Observable<IResponse> {
        return this.http.delete<IResponse>(
            `${environment.server.species}/${id}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * GET Solutions
    * */
    getsSolutions(): Observable<SolutionsInfo[]> {
        return this.http.get<SolutionsInfo[]>(
            `${environment.server.solutions}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * POST Solutions
    * */
    postSolutions(value: SolutionsInfo): Observable<IResponse> {
        return this.http.post<IResponse>(
            `${environment.server.solutions}`,
            {...value},
            {
                responseType: 'json'
            }
        );
    }

    /*
    * PUT species
    * */
    putSolutions(id: string, value: any): Observable<IResponse> {
        return this.http.put<IResponse>(
            `${environment.server.solutions}/${id}`,
            {...value},
            {
                responseType: 'json'
            }
        );
    }

    /*
    * DELETE Solutions
    * */
    deleteSolutions(id: string): Observable<IResponse> {
        return this.http.delete<IResponse>(
            `${environment.server.solutions}/${id}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * GET Substrates
    * */
    getsSubstrates(): Observable<SubstrateInfo[]> {
        return this.http.get<SubstrateInfo[]>(
            `${environment.server.substrates}`,
            {
                responseType: 'json'
            }
        );
    }

    /*
    * POST Substrates
    * */
    postSubstrates(value: SubstrateInfo): Observable<IResponse> {
        return this.http.post<IResponse>(
            `${environment.server.substrates}`,
            {...value},
            {
                responseType: 'json'
            }
        );
    }

    /*
    * PUT Substrates
    * */
    putSubstrates(id: string, value: any): Observable<IResponse> {
        return this.http.put<IResponse>(
            `${environment.server.substrates}/${id}`,
            {...value},
            {
                responseType: 'json'
            }
        );
    }

    /*
    * DELETE Substrates
    * */
    deleteSubstrates(id: string): Observable<IResponse> {
        return this.http.delete<IResponse>(
            `${environment.server.substrates}/${id}`,
            {
                responseType: 'json'
            }
        );
    }
}
