import {Directive} from '@angular/core';

@Directive({
  selector: '[appPageActionsButtons]'
})
export class PageActionsButtonsDirective {

  constructor() { }

}
