import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from '../service/authentication.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UtilsService} from '../service/helper/utils.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private snackBar: MatSnackBar,
        private utilsService: UtilsService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                //this.authenticationService && this.authenticationService.logout();
            }

            const error = err.statusText || err.message || err.error && err.error.message || 'Unexpected error occurred!';

            this.snackBar.open(error, undefined, {
                panelClass: ['danger-color']
            });

            this.utilsService.hideLoader();

            return throwError(`Error: ${error}`);
        }));
    }
}
