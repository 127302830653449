import {BaseInfo} from '../BaseInfo';

export class ModuleInfo extends BaseInfo {
    System = null;
    Modulename = 0;
    RackNumber = 0;
    LightSlotsNumber = 0;
    GrowthSlotsNumber = 0;
    SolutionCapacity = 0;
}
