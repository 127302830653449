import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {User} from '../model/user';
import {OnoApiService} from './ono-api.service';
import {map, switchMap} from 'rxjs/operators';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {CurrentUserService} from './current-user.service';
import {ProfilesService} from './profiles.service';
import {PrivacyService} from './privacy.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService implements Resolve<User> {

    private currentTokenSubject: BehaviorSubject<string>;

    constructor(
        private ono: OnoApiService,
        private currentUserService: CurrentUserService,
        private profilesService: ProfilesService,
        private privacyService: PrivacyService,
    ) {

        this.currentTokenSubject = new BehaviorSubject<string>(JSON.parse(localStorage.getItem('token')));
    }

    public get currentUserValue(): User {
        return this.currentUserService.currentUser;
    }

    public get currentTokenValue(): string {
        return this.currentTokenSubject.value;
    }

    login(username: string, password: string): Observable<boolean> {
        return this.ono.login({username, password}).pipe(
            map(({body: {Code, Token}}) => {
                if (Code === 200) {
                    localStorage.setItem('token', JSON.stringify(Token));
                    this.currentTokenSubject.next(Token);
                }
                return Token;
            }),
            /* retrieve current user */
            switchMap(() => this.currentUserService.retrieveUser()),
            /* retrieve profiles */
            switchMap(() => this.profilesService.retrieveProfiles()),
            switchMap(() => of(true))
        );
    }

    logout() {
        this.currentUserService.removeCurrentUser();
        this.profilesService.removeProfiles();

        localStorage.removeItem('token');
        location.reload();
    }

    refresh() {
        this.ono.refresh().subscribe(v => {
        });
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): User {
        return this.currentUserValue;
    }
}
