import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './modules/app-routing.module';
import {AppComponent} from './app.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
    MAT_SNACK_BAR_DEFAULT_OPTIONS,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatToolbarModule
} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexModule} from '@angular/flex-layout';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './view/pages/login/login.component';
import {BasicAuthInterceptor} from './helpers/basic-auth.interceptor';
import {ErrorInterceptor} from './helpers/error.interceptor';
import {HeaderComponent} from './view/components/layout/header/header.component';
import {FooterComponent} from './view/components/layout/footer/footer.component';
import {CookieService} from 'ngx-cookie-service';
import {OnoApiService} from './service/ono-api.service';
import {AuthenticationService} from './service/authentication.service';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {UserPageComponent} from './view/pages/user-page/user-page.component';
import {AvatarModule} from 'ngx-avatar';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {SvgIconsService} from './service/helper/svg-icons.service';
import {UtilsService} from './service/helper/utils.service';
import {SideBarComponent} from './view/components/layout/side-bar/side-bar.component';
import {PageWrapperComponent} from './view/components/shared/page-wrapper/page-wrapper.component';
import {SystemRegistryComponent} from './view/pages/settings/system-registry/system-registry.component';
import {ModulesListComponent} from './view/components/system/modules/modules-list/modules-list.component';
import {SlotsListComponent} from './view/components/system/slots/slots-list/slots-list.component';
import {LampsComponent} from './view/pages/tmp/lamps/lamps.component';
import {SettingsComponent} from './view/pages/settings/settings.component';
import {ModuleEditDialogComponent} from './view/components/system/modules/module-edit-dialog/module-edit-dialog.component';
import {RackEditDialogComponent} from './view/components/system/racks/rack-edit-dialog/rack-edit-dialog.component';
import {SeedsComponent} from './view/pages/tmp/seeds/seeds.component';
import {SeedEditDialogComponent} from './view/pages/tmp/seeds/seed-edit-dialog/seed-edit-dialog.component';
import {SlotEditDialogComponent} from './view/components/system/slots/slot-edit-dialog/slot-edit-dialog.component';
import {SystemEditDialogComponent} from './view/pages/settings/system-registry/system-edit-dialog/system-edit-dialog.component';
import {DashboardComponent} from './view/pages/dashboard/dashboard.component';
import {PageActionsButtonsDirective} from './view/directive/page-actions-buttons.directive';
import {ModuleInfoComponent} from './view/pages/settings/system-registry/module-info/module-info.component';
import {RacksListComponent} from './view/components/system/racks/racks-list/racks-list.component';
import {RackInfoComponent} from './view/pages/settings/system-registry/rack-info/rack-info.component';
import {UsersComponent} from './view/pages/settings/users/users.component';
import {ProfilesListComponent} from './view/components/profiles/profiles-list/profiles-list.component';
import {UsersListComponent} from './view/components/users-list/users-list.component';
import {UserProfileButtonComponent} from './view/components/shared/buttons/user-profile-button/user-profile-button.component';
import {fakeBackendProvider} from './helpers/fake-backend';
import {SlotInfoComponent} from './view/pages/settings/system-registry/slot-info/slot-info.component';
import {FormPageComponent} from './view/components/shared/form-page/form-page.component';
import {DisableControlDirective} from './view/directive/disable-control.directive';
import {SystemInfoComponent} from './view/pages/settings/system-registry/system-info/system-info.component';
import {ProfileCreateComponent} from './view/pages/settings/profiles/profile-create/profile-create.component';
import {ProfileFormComponent} from './view/components/profiles/profile-form/profile-form.component';
import {ProfileEditComponent} from './view/pages/settings/profiles/profile-edit/profile-edit.component';
import {RegistriesComponent} from './view/pages/settings/registries/registries.component';
import {FarmingsInfoComponent} from './view/pages/settings/registries/farmings-info/farmings-info.component';
import {FarmingsListComponent} from './view/components/registry/farmings/farmings-list/farmings-list.component';
import {FarmingFormComponent} from './view/components/registry/farmings/farmings-form/farming-form.component';
import {PlantsInfoComponent} from './view/pages/settings/registries/plants-info/plants-info.component';
import {ItemsTableComponent} from './view/components/shared/items-table/items-table.component';
import {PlantsFormComponent} from './view/components/registry/plants/plants-form/plants-form.component';
import {SpeciesFormComponent} from './view/components/registry/species/species-form/species-form.component';
import {SpeciesInfoComponent} from './view/pages/settings/registries/species-info/species-info.component';
import {CurrentUserService} from './service/current-user.service';
import {ProfilesService} from './service/profiles.service';
import {SolutionsInfoComponent} from './view/pages/settings/registries/solutions-info/solutions-info.component';
import {SolutionsFormComponent} from './view/components/registry/solutions/solutions-form/solutions-form.component';
import {ConfirmDialogComponent} from './view/components/shared/confirm-dialog/confirm-dialog.component';
import {SubstratesComponent} from './view/pages/settings/substrates/substrates.component';
import {SubstratesFormComponent} from './view/components/registry/substrates/substrates-form/substrates-form.component';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        UserPageComponent,
        SideBarComponent,
        PageWrapperComponent,
        SettingsComponent,
        SystemRegistryComponent,
        ModulesListComponent,
        RacksListComponent,
        SlotsListComponent,
        LampsComponent,
        SeedsComponent,
        ModuleEditDialogComponent,
        RackEditDialogComponent,
        SeedEditDialogComponent,
        SlotEditDialogComponent,
        SystemEditDialogComponent,
        DashboardComponent,
        PageActionsButtonsDirective,
        ModuleInfoComponent,
        RackInfoComponent,
        UsersComponent,
        ProfilesListComponent,
        UsersListComponent,
        UserProfileButtonComponent,
        SlotInfoComponent,
        FormPageComponent,
        DisableControlDirective,
        SystemInfoComponent,
        ProfileCreateComponent,
        ProfileFormComponent,
        ProfileEditComponent,
        RegistriesComponent,
        FarmingsInfoComponent,
        FarmingsListComponent,
        FarmingFormComponent,
        PlantsInfoComponent,
        ItemsTableComponent,
        PlantsFormComponent,
        SpeciesFormComponent,
        SpeciesInfoComponent,
        SolutionsInfoComponent,
        SolutionsFormComponent,
        ConfirmDialogComponent,
        SubstratesComponent,
        SubstratesFormComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FontAwesomeModule,
        MatCardModule,
        MatButtonModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatSidenavModule,
        FlexModule,
        HttpClientModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatIconModule,
        MatListModule,
        MatTableModule,
        AvatarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgxUiLoaderModule,
        MatPaginatorModule,
        MatDialogModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
    ],
    providers: [
        AuthenticationService,
        CookieService,
        OnoApiService,
        CurrentUserService,
        ProfilesService,
        UtilsService,
        SvgIconsService,
        {provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        // provider used to create fake backend
        // fakeBackendProvider,
        /* snack bar provider */
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
                duration: 3000,
                verticalPosition: 'bottom',
                horizontalPosition: 'center'
            }
        },
        /* svg service */
        SvgIconsService,
        UtilsService,
        /* fake server */
        fakeBackendProvider
    ],
    entryComponents: [
        SystemEditDialogComponent,
        ModuleEditDialogComponent,
        RackEditDialogComponent,
        SeedEditDialogComponent,
        SlotEditDialogComponent,
        ConfirmDialogComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
