import {Component, OnInit} from '@angular/core';
import {User} from '../../../../model/user';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {CurrentUserService} from 'src/app/service/current-user.service';
import {AuthenticationService} from '../../../../service/authentication.service';

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

    private user: User;

    constructor(
        private currentUserService: CurrentUserService,
        private authService: AuthenticationService,
        private ngxService: NgxUiLoaderService
    ) {
    }

    ngOnInit() {
        this.user = this.currentUserService.currentUser;
    }

    getFullName() {
        return `${this.user.FirstName} ${this.user.LastName}`;
    }

    logOut() {
        this.ngxService.start();
        this.authService.logout();
    }
}
