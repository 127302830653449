import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SPINNER} from 'ngx-ui-loader';
import {SvgIconsService} from './service/helper/svg-icons.service';
import {AuthenticationService} from './service/authentication.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    spinner = SPINNER;

    constructor(
        private translate: TranslateService,
        private svgIconsService: SvgIconsService,
        private auth: AuthenticationService
    ) {
        /* init i18n module */
        translate.setDefaultLang(localStorage.getItem('userLocale') || 'en');
    }

    isAuth() {
        return !!this.auth.currentUserValue;
    }
}
