import {Component, OnInit} from '@angular/core';
import {OnoApiService} from '../../../service/ono-api.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    constructor(
        private ono: OnoApiService
    ) {
    }

    ngOnInit() {
    }

}
